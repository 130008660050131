




































































































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import ListViewMixin from "@/components/shared/ListViewMixin";
import CustomerService from "@/services/CustomerService";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import StringUtil from "@/utilities/StringUtil";
import RouteUtil from "@/utilities/RouteUtil";

const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");

@Component({
    components: { AlertDialogView },
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            loading: false,
        },
        filter: {
            code: "",
            name: "",
			pageScopedId: "",
            customerGroupCode: "",
        },
        customers: [],
    }),
})
export default class CustomerListView extends Mixins(ListViewMixin) {
    private alertDialog = new AlertDialogProperties();
    private customerService = new CustomerService();

    public get headers() {
        return [
            {
                text: this.$t("text.code"),
                value: "code",
                width: "20%",
            },
            {
                text: this.$t("text.name"),
                value: "formatted.name",
            },
			{
				text: this.$t('text.psid'),
				value: "facebookAccount.pageScopedId",
				width: "20%"
			},
            {
                text: this.$t("text.customer-group"),
                value: "customerGroup.code",
                width: "20%",
            },
            {
                text: this.$t("text.actions"),
                value: "action",
                fixed: true,
                align: "center",
                width: "120px",
                sortable: false,
            },
        ];
    }

    public created() {
        this.listenVisibleChanged();

        const data = this.$data;
        const query = this.$route.query;

        data.table.pageNo = parseInt((query.pageNo as any) ?? 1);
        data.table.recordCount = parseInt((query.recordCount as any) ?? 10);
        data.filter.code = query.code ?? "";
        data.filter.name = query.name ?? "";
		data.filter.pageScopedId = query.psid ?? "";
        data.filter.customerGroupCode = query.customerGroupCode ?? "";

        this.load();
    }

    public destroyed() {
        this.removeVisibleChanged();
    }

    public visibleChanged(e) {
        if (!document.hasFocus()) this.load();
    }

    public async load() {
        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;

        try {
            const filter = this.$data.filter;

            const r = await this.customerService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                code: StringUtil.enclose(filter.code, "%", "%"),
				pageScopedId: StringUtil.enclose(filter.pageScopedId, "%", "%"),
                hidden: false,
				search: filter.name,
				loadFacebookAccount: true,
                loadCustomerGroup: true,
            });
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            const customers: any[] = r.data.customers;

			customers.forEach(e => {
				const f = e.facebookAccount;
				const u = f.uniqueNo ?? null;

				e.formatted = {
					name: u !== null ? `${e.name} [${u}]` : e.name
				};
			});

            this.$data.customers = customers.sort((lhs, rhs) => rhs.id - lhs.id);
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }

            const url = RouteUtil.makeUrl("/management/customers", {
                pageNo: this.$data.table.pageNo,
                recordCount: rowCount,
                code: filter.code,
                name: filter.name,
				psid: filter.pageScopedId,
                customerGroupCode: filter.customerGroupCode,
            });
            if (this.$route.fullPath !== url) {
                await this.$router.replace(url);
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public async userInfo(record: any) {
        var w = window.open("", "_blank");
        try {
            const r = await this.customerService.user(record.id);
            const url = "/management/common/users/modify?id=" + r.data.user.id;
            w.location.href = url;
        } catch (e) {
            w.close();
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        }
    }

    public alertDialogClicked(button: string) {
        this.alertDialog.visible = false;
    }
}
